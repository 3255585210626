import { TNewsCardProps } from '@front/shared/ds';
import * as Sentry from '@sentry/react';
import { useRouter } from 'next/router';
import pino from 'pino';
import { useState, useEffect } from 'react';

export type TNewsResponse = {
  posts: TNewsCardProps[];
};

export enum ETextNewsSortFor {
  Main = 'main',
  Liquidity = 'liquidity',
}

const logger = pino({ level: process.env.LOG_LEVEL || 'info' });
if (process.env.APP_ENV !== 'local') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN_URL,
    tracesSampleRate: 1.0,
  });
}

const isValidResponse = (data: unknown): data is TNewsResponse => {
  if (typeof data !== 'object' || data === null) {
    return false;
  }
  const response = data as TNewsResponse;
  return (
    Array.isArray(response.posts) &&
    response.posts.every(post => {
      return (
        typeof post.title === 'string' &&
        (post.cover === null || typeof post.cover === 'object') &&
        typeof post.link === 'object' &&
        typeof post.link.href === 'string' &&
        typeof post.description === 'string' &&
        typeof post.date === 'string'
      );
    })
  );
};
export type TFetchNewsParams = {
  sortFor?: ETextNewsSortFor;
};
const useFetchNews = (
  newsApiDomain: string,
  newsApiEndpoint: string,
  defaultLangWithPrefix = true,
  params: TFetchNewsParams = {},
): {
  newsPosts: TNewsCardProps[];
  error: string | null;
  loading: boolean;
} => {
  const router = useRouter();
  const locale = router.query.locale?.toString();
  const shouldIncludeLocale =
    defaultLangWithPrefix || (locale && locale !== 'en');

  const endpoint =
    newsApiDomain + (shouldIncludeLocale ? `${locale}` : '') + newsApiEndpoint; // example: http://test.b2binpay.com/en/wp-json/templates/v1/news/

  const normalizedEndpoint = endpoint.replace(/([^:]\/)\/+/g, '$1'); // Remove possible malfunctions using the link, for example //

  const [newsPosts, setNewsPosts] = useState<TNewsCardProps[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const endpointWithParams = new URL(normalizedEndpoint);
    if (params.sortFor) {
      endpointWithParams.searchParams.append('sort_for', params.sortFor);
    }
    const fetchNews = async (): Promise<void> => {
      try {
        const response = await fetch(endpointWithParams);
        if (!response.ok) {
          const msg = `WP News parsing: Error ${response.status}`;
          logger.error(msg);
          Sentry.captureException(new Error(msg));
        }
        const postData: unknown = await response.json();

        if (isValidResponse(postData)) {
          if (postData.posts.length > 0) {
            setNewsPosts(postData.posts);
          } else {
            logger.error('WP News parsing: No posts found');
          }
        } else {
          const msg = 'WP News parsing: Invalid response structure';
          logger.warn(msg);
          Sentry.captureException(new Error(msg));
        }
      } catch (e) {
        const errorMessage = e instanceof Error ? e.message : 'Unknown error';
        setError(errorMessage);
        setLoading(false);
        logger.error(`WP News parsing: ${errorMessage}`);
        Sentry.captureException(e);
      } finally {
        setLoading(false);
      }
    };

    void fetchNews();
  }, [normalizedEndpoint, params.sortFor]);

  return { newsPosts, error, loading };
};

export default useFetchNews;
