import {
  buildGroupingExposByMonth,
  TextsExpoWebinarsRow,
} from '@front/shared/ds';
import { Section } from '@shared/master-types';
import { TMonthGroupProps } from 'front/shared/ds/src/components/atoms/TextExpoWebinarGroup/TextExpoWebinarGroup';
import { useRouter } from 'next/router';
import React from 'react';

import useFetchNews from '../../../hooks/useWPposts';
import { useAppDispatch } from '../../../store/store.hooks';
import toCardButtons from '../../../utils/toCardButtons';

export type TTextsExpoWebinarsWpConnected = Extract<
  Required<Section>['rows'][number],
  { blockType: 'texts-expo-webinars-row-wp' }
>;
const TextsExpoWebinarsRowWPConnected: React.FC<
  TTextsExpoWebinarsWpConnected
> = props => {
  const { eventsApiDomain, eventsApiEndpoint, buttons, bottomContent } = props;

  const router = useRouter();
  const appDispatch = useAppDispatch();
  const currentLocale = String(router.query.locale || '');

  const { newsPosts, error, loading } = useFetchNews(
    eventsApiDomain,
    eventsApiEndpoint,
    false,
  );

  const groupedExposObject = buildGroupingExposByMonth(
    newsPosts,
    currentLocale,
  );
  const groupedExpos: TMonthGroupProps[] = Object.keys(groupedExposObject).map(
    month => ({
      month,
      newsItems: groupedExposObject[month],
    }),
  );

  if (error) {
    return null;
  }

  return (
    <TextsExpoWebinarsRow
      buttons={toCardButtons(buttons, currentLocale, appDispatch)}
      bottomContent={bottomContent}
      groupedExpos={groupedExpos}
      isLoading={loading}
    />
  );
};

export default TextsExpoWebinarsRowWPConnected;
